import React from 'react'
import { injectIntl } from 'react-intl'

import withContext from '../components/withContext'
import injectContext from '../components/injectContext'
import withIntl from '../components/withIntl'
import Space from '../components/Space'
import Layout from '../components/Layout'
import Navigation from '../scenes/Navigation'
import Footer from '../scenes/Footer'
import { Container, Row } from 'react-grid-system'
import SEO from '../components/SEO'

const Message = ({ intl: { formatMessage } }) => (
  <Layout>
    <SEO
      metaTitle={formatMessage({ id: 'meta.message.title' })}
      metaDescription={formatMessage({ id: 'meta.message.description' })}
    />
    <Navigation />
    <Space type="giant" />
    <Container>
      <Row>
        <p>{formatMessage({ id: 'message.body.1' })}</p>
      </Row>
    </Container>
    <Space type="giant" />
    <Footer />
  </Layout>
)

export default withContext(injectContext(withIntl(injectIntl(Message))))
